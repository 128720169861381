.container {
  flex: 1;
  padding-top: 15%;
  min-height: 100vh;
  align-items: center;
  background-color: #d80c20;

  .card {
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    border-radius: 10px;
    position: relative;
    background-color: #ffffff8a;
    box-shadow: 1px 3px 10px 0 rgb(0 0 0 / 39%);

    .loading {
      z-index: 1;
      top: 10px;
      right: 10px;
      width: 20px;
      position: absolute;
    }

    .title {
      color: #FFF;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 15px;
      filter: drop-shadow(2px 2px #333333bd);
    }

    .input {
      color: #333;
      padding: 10px;
      font-size: 1rem;
      border-radius: 5px;
      margin-bottom: 15px;
      background-color: #FFF;
      box-shadow: 1px 3px 10px 0 rgb(0 0 0 / 39%);
    }

    .button {
      padding: 12px;
      color: #FFF;
      font-size: 1rem;
      cursor: pointer;
      font-weight: bold;
      border-radius: 5px;
      margin-bottom: 5px;
      background-color: #d80c20;
      box-shadow: 1px 3px 10px 0 #00000040;
    }

    .button:hover {
      background-color: #720cd8;
    }
  }
}
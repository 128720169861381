.container {
  flex: 1;
  min-height: 100vh;
  background-color: #f5f6f7;

  .logout, .create {
    color: #FFF;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px 30px;
    border-radius: 5px;
    border: 1px solid #FFF;
    background-color: #d80c20;
  }

  .header {
    display: flex;
    padding: 10px 20px;
    flex-direction: row;
    align-items: center;
    background-color: #d80c20;
    justify-content: space-between;

    .title {
      color: #FFF;
      font-weight: 600;
      font-size: 1.5rem;
      text-decoration: none;
      font-family: Battambang;
    }
  }

  .content {
    border-radius: 5px;
    margin: 20px 10%;
    background-color: #FFF;
    box-shadow: 0 0 0.3125rem rgba(23,24,24,.05),0 0.0625rem 0.125rem rgba(0,0,0,.15);

    .info {
      padding: 10px;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      .title {
        padding: 0px 10px;
        font-size: 1.5rem;
        font-weight: 600;
        font-family: Battambang;
      }

      .create {
        padding: 5px 15px;
        font-family: Battambang;
      }
    }

    .list {
      border-collapse: collapse;

      tr th {
        color: #333;
        font-weight: 600;
        border: 1px solid #CCC;
        font-family: Battambang;
      }

      tr td {
        padding: 10px 5px;
        color: #000;
        font-size: 1rem;
        text-align: center;

        img {
          width: 12px;
        }

        span {
          padding: 5px;
          font-size: 1rem;
          margin: 0px 5px;
          border-radius: 50%;
          background-color: #ebeff5;
        }
      }

      tr:nth-child(even) td {
        background-color: #f5f5f5;
      }
      
      tbody tr:hover td {
        background-color: #FFD;
      }
    }
  }
}
.container {
  flex: 1;
  margin: 10px 0px;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  
  .sphere {
    // flex: 1;
    align-items: center;
    position: relative;
    padding: 0px 10px;

    .loading {
      z-index: 0;
      width: 330px;
      position: absolute;
    }

    .glass { 
      z-index: 1;
      width: 330px;
    }

    .shine {
      z-index: 2;
      width: 330px;
      height: 330px;
      position: absolute;
    }

    .box {
      z-index: 3;
      width: 330px;
      height: 330px;
      position: absolute;
      align-items: center;
      justify-content: center;

      .stick {
        width: 330px;
      }

      .logo {
        z-index: 4;
        width: 100px;
        border-radius: 50%;
        position: absolute;
        border: 1px solid #FFF;
        background-color: #d70f22;
      }
    }
  }

  .price {
    flex: 0;
    padding: 10px 0px;
    justify-content: flex-end;

    .title {
      color: #FFF;
      font-size: 2rem;
      font-weight: 600;
      font-family: Battambang;
    }

    .jackpot {
      font-size: 4rem;
      font-weight: bold;
      color: transparent;
      background-clip: text;
      font-family: sans-serif;
      filter: drop-shadow(2px 2px #333);
      background-image: linear-gradient(#FFF 15%, #f7c072, #e19831);
    }
  }

  .spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin { 
    100% { 
      transform: rotate(-360deg); 
    } 
  }

  .result {
    bottom: 0;
    z-index: 6;
    width: 25%;
    position: absolute;
    align-items: center;
    justify-content: center;

    span {
      z-index: 7;
      color: #000;
      font-size: 1.2rem;
      font-weight: bold;
      position: absolute;
      font-family: initial;
    }
  }

  .resultOdd {
    animation: odd 1s linear 1;
  }

  .resultEven {
    animation: even 1s linear 1;
  }

  @keyframes odd {
    0% { transform: translate(-70px, -70px) rotate(-360deg); opacity: 0.6; }
    40% { transform: translate(-50px, -50px); }
    50% { transform: translate(-25px, -45px); }
    60% { transform: translate(-0px, -45px); }
    70% { transform: translate(0px, 0px); }
    80% { transform: translate(0px, 0px); }
    90% { transform: translate(0px, 0px); }
    100% { transform: translate(0px, 0px); }
  }
  @keyframes even {
    0% { transform: translate(70px, -70px) rotate(360deg); opacity: 0.6; }
    40% { transform: translate(50px, -50px); }
    50% { transform: translate(25px, -45px); }
    60% { transform: translate(0px, -45px); }
    70% { transform: translate(0px, 0px); }
    80% { transform: translate(0px, 0px); }
    90% { transform: translate(0px, 0px); }
    100% { transform: translate(0px, 0px); }
  }
}
.container {
  flex: 1;
  min-height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;

  .ball {
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;
  }

  .login {
    top: 10px;
    z-index: 1;
    right: 10px;
    color: #FFF;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px 30px;
    border-radius: 5px;
    position: absolute;
    text-decoration: none;
    background-color: #d80c20;
  }

  .login:hover {
    transform: scale(1.1);
    background-color: #d82e0c;
  }

  .content {
    width: 1000px;
    height: 563px;
    padding: 10px;
    position: relative;
    flex-direction: row;
    border: 1px solid #373c54;
    background-color: #d80c20;

    .lotto {
      flex: 1;
      padding: 0px 10px;
      margin-right: 10px;
      
      .title {
        color: #FFF;
        margin-top: 10px;
        font-size: 1.7rem;
        font-weight: 600;
        line-height: 1.9rem;
        font-family: Battambang;
      }
    }
  }
}
.container {
  position: relative;
  align-items: flex-end;
  justify-content: space-between;

  .logo {
    width: 110px;
  }

  .avatar { 
    width: 300px;
    height: 370px;
    background-color: #d70f22;
  }
}
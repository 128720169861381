.container {
  flex: 1;
  min-height: 100vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .loading {
    width: 40px;
    margin-top: 15px;
  }

  .title {
    color: #d80c20;
    font-size: 4rem;
    font-weight: bold;
    margin-right: 20px;
  }
}
.container {
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-top: 15%;
  position: absolute;
  align-items: center;
  background-color: #00000063;

  .card {
    width: 500px;
    padding: 10px;
    border-radius: 10px;
    background-color: #FFF;

    .header {
      padding: 5px 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .title {
        color: #000;
        font-weight: 600;
        font-size: 1.5rem;
        font-family: Battambang;
      }

      .close {
        color: #000;
        font-size: 1rem;
        cursor: pointer;
        font-weight: bold;
        border-radius: 3px;
      }

      .loading {
        width: 5%;
      }
    }

    .box {
      padding: 20px 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .input {
        width: 70px;
        border-radius: 5px;
        padding: 5px 10px;
        font-size: 2rem;
        text-align: center;
        border: 1px solid #888;
        background-color: #EEE;
      }
    }

    .footer {
      padding: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      button {
        color: #FFF;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 600;
        margin-left: 10px;
        padding: 5px 15px;
        border-radius: 5px;
        font-family: Battambang;
        background-color: #d80c20;
      }

      button:first-child {
        background-color: #AAA;
      }
    }
  }
}
.main {
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: absolute;
  background-color: #00000085;

  .content {
    width: 100%;
    height: 100%;
    max-height: 800px;
    align-items: center;
    justify-content: center;

    .card {
      width: 400px;
      max-height: 350px;
      padding: 15px 20px;
      border-radius: 10px;
      animation-delay: 2s;
      animation: shake 0.5s 1;
      background-color: #FFF;

      .icon {
        font-size: 4rem;
        color: #e10d0d;
      }

      .wrapper {
        margin: 15px 0px;
        overflow-y: scroll;

        .message {
          color: #333;
          font-size: 1rem;
          text-align: center;
          font-family: Battambang;
        }
      }

      .btn {
        color: #FFF;
        padding: 10px;
        font-size: 1rem;
        margin-top: 10px;
        border-radius: 5px;
        line-height: 1.2rem;
        font-family: Battambang;
        background-color: #e10d0d;
      }
    }
  }
}

@keyframes fade {
  100% { opacity: 1 }
  0% { opacity: 0 }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.container {
  position: relative;
  flex-direction: row;
  justify-content: space-between;

  .content {
    padding: 0px 5px;
    position: relative;

    .ball {
      width: 95px;
      height: 95px;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 8px;
      align-items: center;
      justify-content: center;
      background-color: #FFF;
      box-shadow: 1px 1px 5px 5px #34343435;
    }

    .shadow {
      height: 8px;
      width: 95px;
      border-radius: 50%;
      background-color: #0000003d;
    }

    .icon {
      width: 110px;
      height: 110px;
    }

    .result {
      color: #000;
      margin-top: 5px;
      font-size: 2.5rem;
      font-weight: 600;
      position: absolute;
      font-family: Battambang;
    }
  }
}